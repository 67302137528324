const BASE_API = 'http://localhost:8000';

const defaultHeaders = {
  "Content-Type": "application/json; charset=utf-8",
  "Accept": "application/json; charset=utf-8",
  "X-Requested-With": "Edge",
}

function isSandBox() {
  if (typeof window === 'undefined') return false;
  return new URL(window.location.href).host.indexOf('localhost') > -1;
}

export {
  BASE_API,
  defaultHeaders,
  isSandBox,
}

type CookieKey = "token" | "refresh_token" | "backend" | "expires_in" | string


class CookieManager {
  getFromOne(key: CookieKey, defaultValue: any = null) {
    const second = this.getLocal(key);
    if (second) return second;
    const third = this.getSession(key);
    if (third) return third;
    return defaultValue;
  }

  setAll(key: CookieKey, value: string) {
    this.setLocal(key, value);
    this.setSession(key, value);
  }

  removeAll(key: CookieKey) {
    this.remove(key);
    this.removeLocal(key);
    this.removeSession(key);
  }

  clearAll() {
    this.clear();
    this.clearLocal();
    this.clearSession();
  }

  get(key: CookieKey) {
    if (typeof window === 'undefined') return null;
    const cookie = document.cookie.split(';').find((item) => item.indexOf(key) > -1);
    if (!cookie) return null;
    return cookie.split('=')[1];
  }

  set(key: CookieKey, value: string) {
    if (typeof window === 'undefined') return;
    document.cookie = `${key}=${value}`;
  }

  remove(key: CookieKey) {
    if (typeof window === 'undefined') return;
    document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  }

  clear() {
    if (typeof window === 'undefined') return;
    document.cookie.split(';').forEach((item) => {
      const key = item.split('=')[0];
      this.remove(key);
    });
  }

  getLocal(key: CookieKey) {
    if (typeof window === 'undefined') return null;
    const item = localStorage.getItem(key);
    if (!item) return null;
    return JSON.parse(item);
  }

  setLocal(key: CookieKey, value: any) {
    if (typeof window === 'undefined') return null;
    const item = JSON.stringify(value);
    if (!item) return null;
    localStorage.setItem(key, item);
    return true;
  }

  removeLocal(key: CookieKey) {
    if (typeof window === 'undefined') return null;
    localStorage.removeItem(key);
    return true;
  }

  clearLocal() {
    if (typeof window === 'undefined') return null;
    localStorage.clear();
    return true;
  }

  getSession(key: CookieKey) {
    if (typeof window === 'undefined') return null;
    const item = sessionStorage.getItem(key);
    if (!item) return null;
    return JSON.parse(item);
  }

  setSession(key: CookieKey, value: any) {
    if (typeof window === 'undefined') return null;
    const item = JSON.stringify(value);
    if (!item) return null;
    sessionStorage.setItem(key, item);
    return true;
  }

  removeSession(key: CookieKey) {
    if (typeof window === 'undefined') return null;
    sessionStorage.removeItem(key);
    return true;
  }

  clearSession() {
    if (typeof window === 'undefined') return null;
    sessionStorage.clear();
    return true;
  }
}

const Cookie = new CookieManager();

export {
  Cookie,
}