"use client";


import {useEffect, useId, useState} from "react";
import {ClipboardIcon} from "@heroicons/react/24/outline";

type InputProps = {
  id?: string;
  placeholder?: string;

  description?: string;
  title?: string;

  onValueChange?: (value: string) => void;

  type?: "text" | "number" | "password";

  staticValue?: string;
  dynamicValue?: string;
  useFlex?: boolean;
}

const Input = (props: InputProps) => {
  const id = props.id || useId();

  const [value, setValue] = useState<string>(props.staticValue || props.dynamicValue || '')
  const [error, setError] = useState<string>('')

  useEffect(() => {
    if (props.onValueChange) {
      props.onValueChange(value)
    }
  }, [value]);

  console.log({props})

  return <div
    className={
      props.useFlex ?
        "flex flex-row gap-2 justify-center items-center space-x-2" :
        "flex flex-col space-y-1"
    }>
    {
      props.title && <label
            htmlFor={id}
            className="text-lg text-black/80 dark:text-white/80">
        {props.title}
        </label>
    }
    <div className="w-full">
      <div className="relative w-full">
        <input
          id={id}
          name={id}
          type={props.type || "text"}
          style={{
            boxShadow: "0px 0px 2px rgba(0,0,0,0.5)"
          }}
          className={
            "w-full px-5 py-3 rounded-lg focus:outline-none" +
            " focus:ring" +
            " focus:ring-gray-400"
          }
          placeholder={props.placeholder}
          disabled={props.staticValue !== undefined}
          value={value}
          onChange={e => {
            if (props.staticValue) return

            if (props.type == "number") {
              if (isNaN(Number(e.target.value))) return setError("Only numbers are allowed");
              if (Number(e.target.value) < 0) return setError("Number is negative");
              if (Number(e.target.value) > 10000000000) return setError("Number too big");
            }
            setValue(e.target.value)
          }}
        />
        {
          props.staticValue &&
            <span
                className="absolute right-0 top-0 w-full h-full flex justify-end items-center px-3">
                <ClipboardIcon
                    onClick={() => {
                      navigator.clipboard.writeText(value)
                        .then(() => alert("Copied to clipboard"))
                        .catch(() => alert("Failed to copy to clipboard"))
                    }}
                    className="w-6 h-6 text-black/80 dark:text-white/80 cursor-pointer"/>
            </span>
        }
      </div>
      {
        error &&
          <p className="w-full pt-1 pb-1 text-sm font-light text-red-500">
            {error}
          </p>
      }

      {
        props.description &&
          <p className="w-full pt-2 text-sm font-light text-black/80 dark:text-white/80">
            {props.description}
          </p>
      }
    </div>
  </div>
}

export default Input;