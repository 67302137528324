"use client";

import {useEffect, useState} from "react";
import {useRouter} from "next/navigation";
import {BASE_API, Cookie, defaultHeaders} from "@/api";
import {AiFillWarning} from "@react-icons/all-files/ai/AiFillWarning";
import Input from "@/components/Input";


export default function Home() {
  const router = useRouter();
  const [username, setUsername] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [backendUrl, setBackendUrl] = useState<string>('')
  const [error, setError] = useState<string>('')

  const redirectToPanel = () => {
    router.push("/panel");
  }

  useEffect(() => {
    const token = Cookie.getFromOne("token")
    if (token === null) {
      return;
    }
    fetch("/server/auth/check", {
      headers: {
        ...defaultHeaders,
        "X-Backend": Cookie.getFromOne("backend", "1"),
        "Authorization": "Bearer " + token
      }
    })
      .then(r => {
        if (r.status == 200) {
          redirectToPanel();
        }
      })
  }, []);

  const login = (username?: string, password?: string, backend?: string) => {
    fetch("/server/auth/login", {
      method: "POST",
      headers: {
        ...defaultHeaders,
        "X-Backend": backend || BASE_API
      },
      body: JSON.stringify({
        grant_type: "password",
        auth: username,
        password: password
      }),
      cache: "no-cache"
    })
      .then(r => {
        if (r.status == 200) {
          return r.json() as Promise<{
            access_token: string,
            refresh_token: string,
            expires_in: string,
          }>;
        }
        if (r.status == 401) {
          setError("Invalid username or password");
          return null;
        }
        setError("Unknown error");
        return null;
      })
      .then(r => {
        if (r === null) {
          return;
        }
        Cookie.setAll("token", r.access_token)
        Cookie.setAll("refresh_token", r.refresh_token)
        Cookie.setAll("expires_in", r.expires_in)
        if (backend !== undefined) {
          Cookie.setAll("backend", backend);
        }
        redirectToPanel();
      })
      .catch(e => {
        console.error(e)
        setError("Network error");
      })
  }

  return (
    <main id="login"
          className="min-h-screen flex flex-col justify-center items-center bg-gray-50 dark:bg-black text-gray-900 dark:text-gray-50">

      <div id="contaienr" className="container mx-auto px-4 max-w-md">
        {error !== "" &&
            <div
                className="w-full bg-red-600 dark:bg-red-900 text-black dark:text-gray-100 px-6 py-4 flex items-center gap-4 rounded-md shadow mb-7">
                <div>
                    <AiFillWarning/>
                </div>

                <div className="font-light">
                  {error}
                </div>
            </div>
        }


        <div
          id="login"
          className="bg-white dark:bg-white/10 rounded-lg shadow-lg p-8 py-12">
          <h2
            className="text-3xl font-bold text-gray-900 dark:text-gray-50 text-center mb-12">
            Login
          </h2>

          <form
            onSubmit={e => {
              e.preventDefault()
              login(username, password, backendUrl);
            }}
            id="login-form" className="flex flex-col space-y-6">

            <Input
              id={"backend_url"}
              placeholder={"Cloud ID / Server URL"}
              onValueChange={setBackendUrl}
            />

            <Input
              id={"username"}
              placeholder={"Username"}
              onValueChange={setUsername}
            />

            <Input
              id={"password"}
              type={"password"}
              placeholder={"Password"}
              onValueChange={setPassword}
            />

            <div className="flex flex-col space-y-1">
              <button
                style={{
                  boxShadow: "0px 0px 2px rgba(0,0,0,0.5)"
                }}
                id="submit"
                type="submit"
                className="px-5 py-3 rounded-lg bg-blue-500 hover:bg-blue-600 dark:bg-blue-900 dark:hover:bg-blue-800 text-gray-50 font-semibold">
                Login
              </button>
            </div>
          </form>
        </div>
      </div>
    </main>
  )
}

